import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import btn from "./../../../assets/imgs/Assetbtn.svg";

import logo from "./../../../assets/imgs/logo2.32a2f0e40fc629cb6e51.png";

import { verifyPin } from "../../../Utilies/Utilies/zain-api.js";
import { useTranslation } from "react-i18next";

import Layout from "../../../Components/layout.jsx";
import { gtagReportConversion } from "../../../Utilies/Utilies/helper.js";
function PinCodePage() {
  const [errMsg, setErrMsg] = useState("");

  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const location = useLocation();

  const verifyPincode = async () => {
    const phoneNum = location.state || "";
    const time = localStorage.getItem("time");
    const transaction = localStorage.getItem("transaction");
    const trafficsource = localStorage.getItem("trafficsource");
    const parameter = localStorage.getItem("parameter");

    const params = {
      phone: phoneNum,
      otp: otp,
      transaction: transaction,
      time: time,
      trafficsource,
      parameter,
    };
    try {
      const response = await verifyPin(params);
      if (response.sucess === true) {
        gtagReportConversion();
        navigate("/success");
      } else {
        setErrMsg(response.message);
      }
    } catch (error) {
      setErrMsg(error.response.data.message);
      // navigate("/fail");
    }
  };

  return (
    <>
      <Layout>
        <img className="servImg" src={logo} alt="" />

        {/* <h4>{t("SubscribeNowText")}</h4> */}

        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            {t("Enterpincode")}
          </label>
          <input
            type="text"
            className="form-control text-center"
            id="exampleFormControlInput1"
            placeholder="xxxx"
            onChange={(e) => setOtp(e.target.value)}
          />
          {errMsg ? (
            <p className="w-100 overflow-hidden bg-danger-subtle rounded text-danger p-2 mt-3">
              {errMsg}
            </p>
          ) : (
            ""
          )}
          <button
            className="btn btn-sub"
            onClick={() => {
              verifyPincode();
            }}
          >
            <img className={"btn-bg"} src={btn} alt="" />
            <h3 className="btn-txt">{t("confirm")}</h3>
          </button>
        </div>

        <p>{t("feesText")}</p>
      </Layout>
    </>
  );
}

export default PinCodePage;
