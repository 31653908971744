import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import btn from "./../../../assets/imgs/Assetbtn.svg";
import logo from "./../../../assets/imgs/logo2.32a2f0e40fc629cb6e51.png";

import {
  getAntifraud,
  getHeaderEnrichment,
  subscribeRequest,
} from "./../../../Utilies/Utilies/ksa-api";
import { useTranslation } from "react-i18next";

import Layout from "./../../../Components/layout";
import { handlePhoneNumber } from "./../../../Utilies/Utilies/helper";
import ErrorMssg from "./../../../Components/ErrorMssg/ErrorMssg";
import PhoneInput from "./../../../Components/PhoneInput/PhoneInput";
import SubscribeBtn from "./../../../Components/SubscribeBtn/SubscribeBtn";

function KsaSignup() {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  let [isPin, setIsPin] = useState(false);
  const [searchParams] = useSearchParams();
  let time, transaction;
  // Convert searchParams to an object
  const params = Object.fromEntries(searchParams.entries());

  const [phone, setPhone] = useState("");
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);
  useEffect(() => {
    setMarktingParamsToLocalStorage();
  }, [params]);
  const location = useLocation();
  const pin = "";

  const setScript = async (type) => {
    try {
      const response = await getAntifraud(type, "btn-sub");
      const { script } = response.data;
      time = response.data.time;
      transaction = response.data.transaction;
      localStorage.setItem("time", time);
      localStorage.setItem("transaction", transaction);

      console.log("Antifraud Script Response:", response.data);
      const headElement = document.querySelector("head");
      const existingScript = headElement.querySelector("#antifraud-script");

      setTimeout(() => {
        // Embed or replace the script in the <head> section
        if (existingScript) {
          try {
            existingScript.innerHTML = ""; // Clear existing content
            existingScript.innerHTML = script; // Update with new content
            console.log("Existing script updated successfully.");
          } catch (error) {
            console.error("Error removing previous script:", error);
          }
        }
      }, 100); // Add a slight delay

      console.log("Time and Transaction saved to localStorage.");
    } catch (error) {
      console.error("Error fetching antifraud script:", error);
    }
  };
  const getQueryParams = (url) => {
    const queryString = url.split("?")[1]; // Get the part after '?'
    const params = new URLSearchParams(queryString); // Use URLSearchParams for parsing

    // Convert the params to an object
    const paramsObject = {};
    params.forEach((value, key) => {
      paramsObject[key] = value;
    });

    return paramsObject;
  };
  const handleSubscription = async (phone) => {
    if (!isPin) {
      try {
        const response = await getHeaderEnrichment(time, transaction);
        if (response.success === true) {
          const { url } = response;
          const queryParams = getQueryParams(url);
          console.log(queryParams);
          if (url) {
            window.location.href = url;
          }
        }
      } catch (error) {
        setErrMsg(error.response.data.message);
      }
    } else {
      try {
        let phoneNumber = handlePhoneNumber(phone, 964);
        const response = await subscribeRequest(phoneNumber);
        console.log("subscribe Response:", response);
        if (response.success === true) {
          navigate("/ksa-verify", { state: phoneNumber });
        } else {
          setErrMsg(response.message);
        }

        // if (response.status === 200) {
        //   navigate("/pinCode");
        // } else {
        //   navigate("/fail");
        // }
      } catch (error) {
        setErrMsg(error.response.data.message);
      }
    }
  };
  const setMarktingParamsToLocalStorage = () => {
    const trafficsource = params?.trafficsource;
    const parameter = params?.parameter;
    if (trafficsource) {
      localStorage.setItem("trafficsource", trafficsource);
      localStorage.setItem("parameter", parameter);
    }
  };
  useEffect(() => {
    if (location.pathname === "/proceeding") {
      setIsPin(true);
      setScript("pin");
    } else {
      setScript("he");
    }
  }, [pin]);

  return (
    <>
      <Layout>
        <img className="servImg" src={logo} alt="" />

        <h4>{t("SubscribeNowText")}</h4>

        <form
          className="mb-3"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            handleSubscription(phone); // Call the subscribe function with the entered phone number
          }}
        >
          {isPin && (
            <>
              <label htmlFor="phoneInput" className="form-label">
                {t("Enteryourmobilenumber")}
              </label>
              <PhoneInput setPhone={setPhone} />
              {errMsg ? <ErrorMssg errMsg={errMsg} /> : ""}
            </>
          )}
          <SubscribeBtn btn={btn} />
        </form>

        <p>{t("feesText")}</p>
      </Layout>
    </>
  );
}

export default KsaSignup;
